import { useState } from "react";
import { TypedDataUtils } from "eth-sig-util";
import { bufferToHex } from "ethereumjs-util";
import { BigNumber } from "bignumber.js";
import { Typography, Box } from "@mui/material";
import Swal from "sweetalert2";
import { ProposalStatus } from "../../types/common";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import * as EIP712 from "eip712";
import useUserData from "hooks/useUserData";
import useProposalData from "hooks/useProposalData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import saveTransaction from "apis/multeez/saveTransaction";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import rarible from "apis/rarible/client";

export default function Sell() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const [disableButton, setDisableButton] = useState(false);
  const [buttonName, setButtonName] = useState("Confirm");
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  const shouldDisable = () => !proposalData || !userData;

  useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.Sell,
  });

  const lockGasPriceAndRun = async () => {
    setIsActionInProgress(true);
    await confirm();
  };

  const confirm = async () => {
    if (!proposalData || !userData) {
      return;
    }

    try {
      setDisableButton(true);
      setButtonName("Processing....");
      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];
      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: METHOD_GAS_LIMIT,
        user: userData,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      await logInfo("proposal data like in request");
      await logInfo(JSON.stringify(proposalData));
      const nftAddress = proposalData?.tokenAddress;
      const nftId = proposalData?.tokenID;
      const daoAddress = proposalData?.daoAddress;
      const sellPriceEther = proposalData?.proposedValue;
      const sellBidPrice = ethWeb3.utils.toWei(String(sellPriceEther));

      if (proposalData?.proposalStatus !== ProposalStatus.CommitmentNotFull) {
        await logInfo("Proposal is no longer awaiting commitment");
        Swal.fire(
          "Sorry!",
          "Proposal is no longer awaiting commitment",
          "warning"
        );
        return "";
      }

      // Sign the order
      const order = await rarible.createDirectSellOrder(
        nftAddress,
        nftId,
        sellBidPrice
      );
      logInfo(JSON.stringify(order));

      if (!order) {
        logInfo("can not make a bid on NFT");
        setButtonName("Invalid NFT for bid");
        return "";
      }

      await logInfo("creating dao instance");
      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logError(
          "DAO instance failed to be created user needs to try again later, no money was spent"
        );
        alert(
          "DAO instance failed to be created please try again later, no money was spent"
        );
        return "";
      }

      order.maker = daoAddress; // adding the dao address to the order
      const orderEncoded = await rarible.encodeOrder(order);
      const [hash, sig] = await orderSigner(orderEncoded, userAddress, ethWeb3);
      order.signature = sig;
      //dao.methods.updateMyHash(hash,sig).send({from : userAddress,gasPrice:gasUnitPrice});
      await updateUserHash([
        hash,
        sig,
        dao,
        userAddress,
        gasUnitPrice,
        proposalData,
        userData.id,
        order,
        setIsActionInProgress,
      ]);
      await logInfo("update hash response");
    } catch (e) {
      setButtonName("Failed to create bid on Rarible");
      console.log(e);
    }
  };

  return (
    <Page>
      {(!userData || isActionInProgress) && (
        <Loading
          text={`${
            !userData ? "Loading" : "Getting things ready..."
          }, please wait...`}
        />
      )}
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {showAddGasModal && (
          <AddGasModal
            open={showAddGasModal}
            onClose={() => setShowAddGasModal(false)}
            gasPriceInWei={gasNeeded}
            onFundsAdded={confirm}
            userEmail={userData?.email || ""}
          />
        )}
        <Typography mt={13} mb={3}>
          We&apos;re taking care of stuff, hold on
        </Typography>
        <p>
          Estimated Gas Price:{" "}
          <strong>
            {toFixed(
              new BigNumber(
                ethWeb3.utils.fromWei(gasUnitPrice.toString())
              ).multipliedBy(String(METHOD_GAS_LIMIT))
            )}{" "}
            ETH
          </strong>
          <br />
          Once approved, a 2% service fee will be deducted from the transaction
        </p>
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={lockGasPriceAndRun}
        >
          {buttonName}
        </Button>
      </Box>
    </Page>
  );
}

async function orderSigner(orderEncoded, userAddress, ethWeb3) {
  const data = EIP712.createTypeData(
    orderEncoded.signMessage.types,
    orderEncoded.signMessage.structType,
    orderEncoded.signMessage.domain,
    orderEncoded.signMessage.struct
  );
  await logInfo("fromAddress: " + userAddress);
  const signedMessage = await EIP712.signTypedData(ethWeb3, userAddress, data);
  const hash = bufferToHex(TypedDataUtils.sign(data));
  const sig = signedMessage.sig;
  await logInfo("signature: " + sig);
  await logInfo("hash: " + hash);
  return [hash, sig];
}

async function updateUserHash([
  hash,
  sig,
  dao,
  userAddress,
  gasUnitPrice,
  proposal,
  userId,
  order,
  setIsActionInProgress,
]) {
  dao.methods
    .updateMyHash(hash, sig)
    .send({ from: userAddress, gasPrice: gasUnitPrice, gas: METHOD_GAS_LIMIT })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        proposal,
        userId,
        originAction: TransactionOriginAction.Sell,
        onComplete: () => setIsActionInProgress(false),
        transactionOrder: order,
      })
    );
}
