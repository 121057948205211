export type ProposalVoter = {
  dynamoUserId: string;
  telegramUserId: number;
  magicWallet: string;
  contribution: number;
  textTime: number;
  messageId: number;
  NumberOfUpdates: number;
  fundsLocked: boolean;
  approveSell: boolean;
};

export enum TransactionStatus {
  Success = "Success",
  Pending = "Pending",
}

export enum ProposalProgress {
  Failed = "Failed",
  Won = "Won",
  CommitmentNotFull = "CommitmentNotFull",
  PendingFunding = "PendingFunding",
  PendingDeployment = "PendingDeployment",
  PendingTransferToDao = "PendingTransferToDao",
  BidPlaced = "BidPlaced",
  OutBid = "OutBid",
  Importing = "Importing",
  Exported = "Exported",
}

export type Transaction = {
  id: string; // the transaction dynamo Id - TRANSACTION_{dynamoProposalId}_{dynamoUserId}
  kind: string; // hard coded TRANSACTION
  proposalId: string; // the related proposalId if exists
  userId: string; // the related user id
  hash: string; // the transaction hash
  order: string; // the transaction order (stringified json)
  transactionStartTime: string; // the time the transaction was sent to the system
  transactionEndTime: string; // the time the transaction entity was closed
  closed: boolean; // whether or not the transaction was closed
  status: TransactionStatus; // the transaction status "success", "falied" or "pending"
  originAction: string; // the web app page from which the transaction started
  schemaVersion: number; // the schemaversion
  buyoutSecondUserId: string; // only in buyout transactions - the second user
  daoAddress: string; // the related DAO address if relevant
  proposalTime: number;
};

export enum ProposalStatus {
  Failed = "Failed",
  Won = "Won",
  CommitmentNotFull = "CommitmentNotFull",
  PendingFunding = "PendingFunding",
  PendingDeployment = "PendingDeployment",
  BidPlaced = "BidPlaced",
  OutBid = "OutBid",
  Importing = "Importing",
  Exported = "Exported",
}

export type Proposal = {
  id: string;
  proposer: string;
  isDirectBuy: boolean;
  proposalTime: number;
  proposalStatus: ProposalStatus;
  proposedValue: number;
  tokenAddress: string;
  tokenID: string;
  proposeKind: string;
  remainingValue: number;
  voters: ProposalVoter[];
  messageId: number;
  imageUrl: string;
  nftName: string;
  raribleUrl: string;
  telegramChatId: number;
  daoAddress: string;
  deployCost: string;
  gasUnitPriceAtProposalTime: number;
  originalOwner: string;
  kind: string;
  onRaribleTime: string;
  transactionCompleteTime: string;
  originalProposal: string;
  proposedShare: number;
  totalUserShare: number;
  importCommand: boolean;
  supergroupChatId: number;
  slots: number;
  closed: boolean;
  voterAmountOwedToMulteez: string;
  directBuySignature: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phoneNumber: string;
  lockedFunds: number;
  magicWallet: string;
};
