import { useCallback } from "react";
import { Outlet, useOutletContext } from "react-router";
import Swal from "sweetalert2";
import ClipboardJS from "clipboard";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import * as S from "./Wallet.styles";
import TabLink from "./components/TabLink";
import useUserData from "hooks/useUserData";
import useUserFreeBalance from "hooks/useUserFreeBalance";
import { addEllipses } from "utils/common";
import Page from "components/Page";
import { ReactComponent as CopyIcon } from "assets/copy.svg";
import { ReactComponent as DepositIcon } from "assets/arrow-down.svg";
import { ReactComponent as ActivityIcon } from "assets/menu.svg";
import { ReactComponent as TransferIcon } from "assets/transfer-arrows.svg";
import { User } from "types/common";

new ClipboardJS(".copy-address-btn");

type ContextType = {
  userData: User | null;
  userFreeBalance: string;
};

export function useUser() {
  return useOutletContext<ContextType>();
}

const Wallet = () => {
  const { data: userData } = useUserData();
  const { data: userFreeBalance, isLoading: isUserBalanceLoading } =
    useUserFreeBalance(userData?.email, userData?.magicWallet);

  const showFeedbackOnCopy = useCallback(() => {
    Swal.fire({
      title: "Address copied!",
      icon: "success",
      showConfirmButton: false,
      timer: 1000,
    });
  }, []);

  return (
    <Page>
      <Helmet>
        <script
          src={`https://verify.${
            process.env.REACT_APP_WYRE_ENVIRONMENT === "test" ? "test" : "send"
          }wyre.com/js/verify-module-init-beta.js`}
        />
      </Helmet>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" fontWeight={900}>
          Multeez Wallet
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          height={95}
        >
          <S.BalanceBox>
            <Typography variant="caption" color="#5F51EC">
              Balance
            </Typography>
            {isUserBalanceLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography fontWeight={300} component="span" variant="h4">
                {userFreeBalance?.toFixed(4)}
                <Typography
                  variant="caption"
                  ml={1}
                  color="#5F51EC"
                  fontWeight={300}
                >
                  ETH
                </Typography>
              </Typography>
            )}
          </S.BalanceBox>
        </Box>
        <S.AddressBox>
          <Box
            id="public-address"
            sx={{
              position: "absolute",
              left: -9999,
              opacity: 0,
              height: 0,
              width: 0,
            }}
          >
            {userData?.magicWallet}
          </Box>
          <Typography variant="body1" fontWeight={900} color="#4F42CC">
            {addEllipses(userData?.magicWallet as string, 12)}
          </Typography>
          <CopyIcon
            className="copy-address-btn"
            data-clipboard-target="#public-address"
            style={{ cursor: "pointer" }}
            onClick={showFeedbackOnCopy}
          />
        </S.AddressBox>
        <Stack direction="row" justifyContent="space-between" px={5} mb={2}>
          <TabLink to="/wallet" icon={ActivityIcon} label="Activity" />
          <TabLink to="deposit" icon={DepositIcon} label="Deposit" />
          <TabLink to="transfer" icon={TransferIcon} label="Transfer" />
        </Stack>
        <S.BottomSection>
          <Outlet
            context={{
              userData,
              userFreeBalance,
            }}
          />
        </S.BottomSection>
      </Box>
    </Page>
  );
};

export default Wallet;
