import { useCallback, useState } from "react";
import { BigNumber } from "bignumber.js";
import { InputAdornment, Stack, Typography } from "@mui/material";
import * as S from "./Deposit.styles";
import { useUser } from "pages/Wallet";
import AddFundsButton from "components/AddFundsButton";
import useQueryParams from "hooks/useQueryParams";
import { wyreTransfer } from "utils/wyreTransfer";
import { isMetaMaskInstalled, metamaskTransfer } from "utils/metamaskTransfer";

const Deposit = () => {
  const { userData } = useUser();
  const queryParams = useQueryParams();
  const minimumAmount = queryParams.get("minimalDeposit")
    ? new BigNumber(queryParams.get("minimalDeposit")).plus(0.0001).toString()
    : "0.1";
  const [amountError, setAmountError] = useState(false);
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [depositAmount, setDepositAmount] = useState<string>("1.0");
  const [, setIsTransactionInProgress] = useState(false);

  const onAmountChange = ({ target: { value } }) => {
    setIsInputTouched(true);

    if (!value) {
      setDepositAmount("");
      return;
    }

    const numberRegex = /^\d+\.?\d*$/;

    numberRegex.test(value) && setDepositAmount(value);
  };

  const onBlur = () =>
    setAmountError(
      depositAmount
        ? new BigNumber(depositAmount).isLessThan(minimumAmount)
        : true
    );

  const handleWyreClick = useCallback(() => {
    wyreTransfer({
      ethAmountToAdd: Number(depositAmount) || 0,
      userEmail: userData?.email || "",
    });
  }, [userData, depositAmount]);

  const handleMetaMaskClick = useCallback(() => {
    metamaskTransfer({
      publicAddress: userData?.magicWallet || "",
      amountToSendInEth: depositAmount || "0",
      setIsTransactionInProgress,
      setEthAmountToAdd: (amount: number) => setDepositAmount(String(amount)),
      userEmail: userData?.email || "",
    });
  }, [userData, depositAmount]);

  return (
    <>
      <Typography variant="body1" fontWeight={800} mt={5} mb={2} align="center">
        Deposit Funds
      </Typography>
      <Typography align="center" variant="body2" mb={6}>
        Based on the NFT proposals you are looking at, we recommend adding an
        extra 10% to your deposit to cover future gas costs. Any unused funds
        will remain in your wallet available for withdrawal.
      </Typography>
      <Stack gap={3}>
        <S.Input
          touched={isInputTouched}
          value={depositAmount}
          onChange={onAmountChange}
          variant="standard"
          onBlur={onBlur}
          placeholder="Enter amount"
          error={amountError}
          helperText={
            amountError
              ? `Please deposit at least ${minimumAmount}ETH. The minimum is calculated based on your current NFT commitments.`
              : ""
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">ETH</InputAdornment>,
          }}
        />
        <AddFundsButton
          provider="metamask"
          onClick={handleMetaMaskClick}
          disabled={!isMetaMaskInstalled() || amountError}
        />
        <AddFundsButton
          provider="wyre"
          onClick={handleWyreClick}
          disabled={amountError}
        />
      </Stack>
    </>
  );
};

export default Deposit;
