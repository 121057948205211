import { BigNumber } from "bignumber.js";
import Swal from "sweetalert2";
import { useCallback, useState } from "react";
import { getUserBid, orderSigner } from "../utils";
import { logError, logInfo } from "../../../utils/logger";
import { ethWeb3 } from "../../../magic";
import {
  calcMissingFundsForActionWei,
  ADD_FUNDS_GAS_UNITS,
  RARIBLE_DIRECT_BUY_FEE,
  getDaoIfExists,
} from "../../../utils";
import { Proposal } from "../../../types/common";
import useUserData from "../../../hooks/useUserData";
import rarible from "../../../apis/rarible/client";
import { addFundsToDao } from "./utils";

type DeployDaoParams = {
  proposal?: Proposal;
  gasUnitPrice: string;
  setShowAddGasModal: (show: boolean) => void;
};

const useDeployDaoOrAddFunds = ({
  proposal,
  gasUnitPrice,
  setShowAddGasModal,
}: DeployDaoParams) => {
  const { data: userData } = useUserData();

  const [missingFunds, setMissingFunds] = useState("0");
  const [isDeployInProgress, setIsDeployInProgress] = useState(false);

  const deployDaoOrAddFunds = useCallback(async () => {
    if (!proposal || !userData || !gasUnitPrice) {
      return;
    }

    try {
      // Deploy the DAO
      setIsDeployInProgress(true);
      const nftAddress = proposal?.tokenAddress;
      const nftId = proposal?.tokenID;
      const bidPriceEther = proposal?.proposedValue;

      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];

      const userBidEther = getUserBid(proposal.voters, userData.id);
      const totalBidWei = proposal.isDirectBuy
        ? new BigNumber(ethWeb3.utils.toWei(String(bidPriceEther)))
            .plus(
              new BigNumber(
                ethWeb3.utils.toWei(String(bidPriceEther))
              ).multipliedBy(RARIBLE_DIRECT_BUY_FEE)
            )
            .toString()
        : ethWeb3.utils.toWei(String(bidPriceEther));
      await logInfo(`Total bid ${totalBidWei}`);
      const userBidWei = proposal.isDirectBuy
        ? new BigNumber(ethWeb3.utils.toWei(String(userBidEther)))
            .plus(
              new BigNumber(
                ethWeb3.utils.toWei(String(userBidEther))
              ).multipliedBy(RARIBLE_DIRECT_BUY_FEE)
            )
            .toString()
        : ethWeb3.utils.toWei(String(userBidEther));
      const userBidBeforeMulteezFeeBN = new BigNumber(userBidWei);
      const userBidWithFees = userBidBeforeMulteezFeeBN.plus(
        proposal.voterAmountOwedToMulteez
      );
      let fundsMissingForAction;

      if (!proposal?.daoAddress || proposal?.daoAddress === "-") {
        logError("user got to transfer funds page with no dao!");
        return;
      } else {
        logInfo("yes dao, adding funds to it");
        fundsMissingForAction = await calcMissingFundsForActionWei({
          gasUnitPrice,
          gasUnits: ADD_FUNDS_GAS_UNITS,
          user: userData,
          fundsForFeeCalculationInWei: ethWeb3.utils
            .toWei(String(userBidEther))
            .toString(),
          additionalFeesInWei: new BigNumber(proposal.voterAmountOwedToMulteez),
          raribleFeeInfo: proposal.isDirectBuy
            ? {
                proposedValue: new BigNumber(
                  ethWeb3.utils.toWei(String(proposal?.proposedValue))
                ).toNumber(),
                slots: proposal?.slots,
              }
            : undefined,
        });
      }

      logInfo(
        `amount needed for transaction ${fundsMissingForAction.toString()}`
      );

      if (!fundsMissingForAction.isEqualTo(0)) {
        setMissingFunds(fundsMissingForAction.toString());
        logInfo(
          `to low balance for request with additional needed is ${fundsMissingForAction.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const dao = await getDaoIfExists(proposal);

      if (!dao) {
        /* To do: needs to think about failed instance dao   */
        logError(
          `failed to create dao instance from backend with dao address ${proposal?.daoAddress} and user address ${userData.magicWallet}`
        );
        return;
      } else {
        logInfo("create dao instance");
        dao.receipt = {};
        dao.receipt.deployCost = proposal?.deployCost;
      }

      const directBuyTransfer = proposal?.isDirectBuy
        ? await rarible.createOrdersForBuyAddFunds(
            proposal.tokenAddress,
            proposal.tokenID,
            dao.options.address,
            proposal.directBuySignature
          )
        : undefined;

      const bidOrder = proposal?.isDirectBuy
        ? undefined
        : await rarible.createBidOrder(
            nftAddress,
            nftId,
            totalBidWei,
            dao.options.address
          );
      const order = proposal.isDirectBuy
        ? directBuyTransfer?.buyOrderEncoded
        : bidOrder?.bidEncodedOrder;

      if (!order) {
        //TODO: handle
        await logError(
          `Failed to create bid for NFT ${nftAddress}:${nftId} for user ${userData.magicWallet}`
        );
        return;
      }

      const [hash, sig] = await orderSigner(order, userAddress);
      logInfo("transferring funds dao");

      if (bidOrder) {
        bidOrder.rawOrder.signature = sig;
      }

      await addFundsToDao({
        hash,
        sig,
        userAddress,
        userBid: userBidWithFees.toString(),
        userBidBeforeMulteezFeeBN,
        dao,
        gasUnitPrice,
        order: proposal.isDirectBuy ? order : bidOrder?.rawOrder,
        proposal,
        orderForBuyAddFunds: directBuyTransfer,
        userId: userData.id,
        setIsDeployInProgress,
      });
    } catch (e) {
      setIsDeployInProgress(false);
      Swal.fire({
        title: "Failed to create bid on Rarible",
        icon: "error",
        showConfirmButton: true,
      });
      logError(`general error in deploy with error ${String(e)}`);
    }
  }, [proposal, gasUnitPrice, userData]);

  return { missingFunds, isDeployInProgress, deployDaoOrAddFunds };
};

export default useDeployDaoOrAddFunds;
