import { useEffect, useState } from "react";
import Web3 from "web3";
import { BigNumber } from "bignumber.js";
import { ethWeb3 } from "../magic";
import { logInfo } from "../utils/logger";
import { Proposal, User } from "../types/common";
import {
  ADD_FUNDS_GAS_UNITS,
  calcMissingFundsForActionWei,
  RARIBLE_DIRECT_BUY_FEE,
} from "../utils";
import getFreeUserBalance from "../utils/getFreeUserBalance";

const GAS_UNIT_PRICE_CEILING = 160000000000; // 160 gwei

const calcUserRaribleFee = (proposal: Proposal, slots: number) =>
  proposal.isDirectBuy
    ? new BigNumber(proposal.proposedValue)
        .multipliedBy(RARIBLE_DIRECT_BUY_FEE)
        .dividedBy(slots)
        .toFixed(5)
    : "0";

type CheckHookProps = {
  proposal: Proposal | undefined;
  setDisplayedBalance: (bal: string) => void;
  user: User | undefined;
};

export type UseProposalInfoResp = {
  freeBalance: number;
  setFreeBalance: (bal: number) => void;
  setEthAmountToAdd: (bal: number) => void;
  userContribution: number;
  isSetupInProgress: boolean;
  originalBalance: string;
  userBid: number;
  userGasSurcharge: string;
  userRaribleFeeEth: string;
  fullUserBidEth: number;
  ethAmountToAdd: number;
  voterAmountOwedToMulteezInEth: string;
  userFees: string;
};

const useUserProposalInfo = ({
  proposal,
  setDisplayedBalance,
  user,
}: CheckHookProps): UseProposalInfoResp => {
  const [freeBalance, setFreeBalance] = useState(0);

  useEffect(() => {
    if (!user) {
      return;
    }

    getFreeUserBalance(user?.email, user?.magicWallet).then((bal) =>
      setFreeBalance(bal)
    );
  }, [user]);

  const [userBid, setUserBid] = useState(0);
  const [userContribution, setUserContribution] = useState(0);
  const [isSetupInProgress, setIsSetupInProgress] = useState(true);
  const [originalBalance, setOriginalBalance] = useState("0");
  const [userGasSurcharge, setUserGasSurcharge] = useState("0");
  const [userRaribleFeeEth, setUserRaribleFeeEth] = useState("0");
  const [ethAmountToAdd, setEthAmountToAdd] = useState(0);
  const [fullUserBidEth, setFullUserBidEth] = useState(0);
  const [voterAmountOwedToMulteezInEth, setVoterAmountOwedToMulteezInEth] =
    useState("0");
  const [userFees, setUserFees] = useState("0");

  useEffect(() => {
    async function updateProposalInfo() {
      if (!proposal || !user || freeBalance === undefined) return;

      const { magicWallet: publicAddress } = user;

      const weiBalance = await ethWeb3.eth.getBalance(publicAddress);
      await logInfo(`address ${publicAddress} balance is ${weiBalance} `);
      const ethBalance = ethWeb3.utils.fromWei(weiBalance);
      setOriginalBalance(ethBalance);
      setDisplayedBalance(ethBalance);

      const { voters, slots } = proposal;

      const userRaribleFeeShare = calcUserRaribleFee(proposal, slots);
      setUserRaribleFeeEth(userRaribleFeeShare);

      const voterAmountOwedToMulteezInEth = new BigNumber(
        ethWeb3.utils.fromWei(proposal.voterAmountOwedToMulteez)
      ).toFixed(5);
      setVoterAmountOwedToMulteezInEth(voterAmountOwedToMulteezInEth);
      const userBid =
        voters.find((voter) => voter.dynamoUserId === user.id)?.contribution ||
        0;

      setUserBid(Number(userBid));
      setUserContribution(userBid);
      const gasUnits = ADD_FUNDS_GAS_UNITS;
      const userGasSurcharge = Web3.utils.fromWei(
        new BigNumber(gasUnits).multipliedBy(GAS_UNIT_PRICE_CEILING).toString()
      );
      setUserGasSurcharge(userGasSurcharge);
      const raribleFeeInfo = proposal.isDirectBuy
        ? {
            proposedValue: new BigNumber(
              Web3.utils.toWei(String(proposal.proposedValue))
            ).toNumber(),
            slots: proposal.voters.length,
          }
        : undefined;
      const userBidEWei = new BigNumber(Web3.utils.toWei(String(userBid)));
      logInfo(
        `rarible info for missing funds calculation ${JSON.stringify(
          JSON.stringify(raribleFeeInfo)
        )}`
      );
      const missingFeesWei = await calcMissingFundsForActionWei({
        user,
        gasUnitPrice: GAS_UNIT_PRICE_CEILING.toString(),
        gasUnits,
        fundsForFeeCalculationInWei: userBidEWei.toString(),
        additionalFeesInWei: new BigNumber(proposal.voterAmountOwedToMulteez),
        raribleFeeInfo,
      });

      const missingFeesEth = Web3.utils.fromWei(missingFeesWei.toString());

      setEthAmountToAdd(Number(missingFeesEth));
      const fullBidPrice = new BigNumber(missingFeesEth).plus(freeBalance);
      setFullUserBidEth(Number(fullBidPrice.toFixed(5)));

      const userFees = new BigNumber(userGasSurcharge)
        .plus(voterAmountOwedToMulteezInEth)
        .plus(userRaribleFeeShare)
        .toString();
      setUserFees(userFees);
    }

    updateProposalInfo().then(() => setIsSetupInProgress(false));
  }, [freeBalance, proposal]);

  return {
    userContribution,
    freeBalance,
    setFreeBalance,
    isSetupInProgress,
    originalBalance,
    userBid,
    userGasSurcharge,
    userRaribleFeeEth,
    ethAmountToAdd,
    setEthAmountToAdd,
    fullUserBidEth,
    voterAmountOwedToMulteezInEth,
    userFees,
  };
};

export { useUserProposalInfo };
