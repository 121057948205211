import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Loading from "../components/Loading";
import useAuth, { AuthProvider } from "../context/useAuth";
import Callback from "./Callback";
import Deploy from "./Deploy";
import Login from "./Login";
import Signup from "./Signup";
import Sell from "./Sell";
import RequestBuyout from "./RequestBuyOut";
import Buyout from "./BuyOut";
import Withdraw from "./Withdraw";
import Byon from "./ByonFlow/index";
import WithdrawNft from "./WithdrawNft";
import Onboarding from "./Onboarding";
import Wallet from "./Wallet";
import AddFunds from "./AddFunds";
import Deposit from "./Wallet/components/Deposit";
import Activity from "./Wallet/components/Activity";
import Transfer from "./Wallet/components/Transfer";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body: React.CSSProperties;
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body: true;
    body3: true;
  }
}

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body: "p",
          body3: "p",
        },
      },
    },
  },
  typography: {
    fontFamily: "Lato",
    body: {
      fontSize: "1.125rem",
    },
    body3: {
      fontSize: "0.8125rem",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoading, isLoggedIn } = useAuth();

  const location = useLocation();

  if (isLoading) return <Loading />;

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <div className="App">
            <AuthProvider>
              <Routes>
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/onboarding"
                  element={
                    <RequireAuth>
                      <Onboarding />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/callback"
                  element={
                    <RequireAuth>
                      <Callback />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/wallet"
                  element={
                    <RequireAuth>
                      <Wallet />
                    </RequireAuth>
                  }
                >
                  <Route
                    path="deposit"
                    element={
                      <RequireAuth>
                        <Deposit />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="transfer"
                    element={
                      <RequireAuth>
                        <Transfer />
                      </RequireAuth>
                    }
                  />
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <Activity />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route
                  path="/deploy"
                  element={
                    <RequireAuth>
                      <Deploy />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/sell"
                  element={
                    <RequireAuth>
                      <Sell />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/RequestBuyout"
                  element={
                    <RequireAuth>
                      <RequestBuyout />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/Buyout"
                  element={
                    <RequireAuth>
                      <Buyout />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/Withdraw"
                  element={
                    <RequireAuth>
                      <Withdraw />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/byon"
                  element={
                    <RequireAuth>
                      <Byon />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/WithdrawNFT"
                  element={
                    <RequireAuth>
                      <WithdrawNft />
                    </RequireAuth>
                  }
                />
                <Route
                  path={"/addFunds"}
                  element={
                    <RequireAuth>
                      <AddFunds />
                    </RequireAuth>
                  }
                />
                <Route path="*" element={<Navigate replace to="/wallet" />} />
              </Routes>
            </AuthProvider>
          </div>
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
