import { BigNumber } from "bignumber.js";
import { ERC721ABI } from "./contract/ERC721";
import { logError, logInfo } from "./utils/logger";
import { User } from "./types/common";
import { ethWeb3 } from "./magic";
import { ERC1155 } from "./contract/ERC1155";
import { abi } from "./contract/contract";

//required not to break contract
const MULTEEZ_FEE_PRCT = 0;

export const ERC721NameConvention = "ERC721";
export const ERC1155NameConvention = "ERC1155";

export const SINGLE_TRANSACTION_GAS_LIMIT = 21000;
export const METHOD_GAS_LIMIT = 250000;
export const DEPLOY_DAO_GAS_UNITS = 2500000;
export const RARIBLE_BUY_GAS_UNITS = 700000;
export const ADD_FUNDS_GAS_UNITS = 350000;
export const RARIBLE_DIRECT_BUY_FEE = 0.025;
export const RARIBLE_ETH_CONTRACT_CURRENCY_ID =
  "0x0000000000000000000000000000000000000000";
export const GAS_PRICE_GUARD_MULTIPLIER = 1.2;

export async function runWithRetries<T>(
  func: (args: T) => any,
  args: T,
  numAttempts = 3
): Promise<any> {
  let lastError;

  for (let i = 0; i < numAttempts; i++) {
    try {
      const res = await func(args);
      return res;
    } catch (e) {
      await logError(`tryCath wrapper failed in the ${String(i)}`);
      await logError(e);
      lastError = e;

      if (
        String(e) ==
        "Error: Magic RPC Error: [-32603] Error forwarded from node: insufficient funds for gas * price + value"
      ) {
        await logError("insufficient funds pulling out");
        break;
      }
    }
  }

  throw (
    `Wrapper function failed ${numAttempts} times with error message ` +
    lastError
  );
}

export async function createContractInstance(contractAddress: string) {
  return new ethWeb3.eth.Contract(abi, contractAddress);
}

export async function isDaoHoldsThisNft(
  nftAddress: string,
  tokenId: string,
  daoAddress: string
) {
  const nftAttempt = await new ethWeb3.eth.Contract(ERC721ABI.abi, nftAddress);

  try {
    const ownerOfErc721 = await nftAttempt.methods.ownerOf(tokenId).call();
    if (ownerOfErc721 === daoAddress) return true;
  } catch (e) {
    logInfo("not 721");

    try {
      const nftAttempt1155 = await new ethWeb3.eth.Contract(
        ERC1155.abi,
        nftAddress
      );
      const holdingAmountOfDao = await nftAttempt1155.methods
        .balanceOf(daoAddress, tokenId)
        .call();

      if (holdingAmountOfDao > 0) {
        return true;
      }
    } catch (eErc1155) {
      await logError(
        `Error when trying to figure out nft type and if dao holds the nft might be lazy minting, with error:`
      );
      await logError(eErc1155);
    }
  }

  return false;
}

type CalcMissingFundsForAuxActionFees = {
  gasUnitPrice?: string;
  gasUnits?: number;
  user: User;
  raribleFeeInfo?: { proposedValue: number; slots: number };
  fundsForFeeCalculationInWei?: string;
  additionalFeesInWei?: BigNumber;
};

export async function calcMissingFundsForActionWei({
  gasUnitPrice,
  gasUnits,
  user,
  raribleFeeInfo,
  fundsForFeeCalculationInWei = "0",
  additionalFeesInWei = new BigNumber(0),
}: CalcMissingFundsForAuxActionFees): Promise<BigNumber> {
  if (!user) {
    return new BigNumber(0);
  }

  const lockedFundsWei = new BigNumber(
    ethWeb3.utils.toWei(String(user.lockedFunds))
  );

  const accountBalanceWei = new BigNumber(
    await ethWeb3.eth.getBalance(user.magicWallet)
  );

  let freeBalance = accountBalanceWei.minus(lockedFundsWei);

  if (freeBalance.isLessThanOrEqualTo(0)) {
    freeBalance = new BigNumber(0);
  }

  const gasPayment =
    gasUnitPrice && gasUnits
      ? new BigNumber(gasUnitPrice).multipliedBy(gasUnits)
      : new BigNumber(0);
  const multeezFee = new BigNumber(fundsForFeeCalculationInWei).multipliedBy(
    MULTEEZ_FEE_PRCT
  );
  const raribleFee = raribleFeeInfo
    ? new BigNumber(raribleFeeInfo.proposedValue.toString())
        .multipliedBy(RARIBLE_DIRECT_BUY_FEE)
        .dividedBy(raribleFeeInfo.slots)
        .toNumber()
    : 0;
  const totalFundsNeeded = gasPayment
    .plus(multeezFee)
    .plus(additionalFeesInWei)
    .plus(raribleFee)
    .minus(freeBalance);

  if (totalFundsNeeded.isLessThanOrEqualTo(0)) {
    return new BigNumber(0);
  }

  // Wei can't have decimal points, remove them.
  logInfo(`total funds in wie to add ${totalFundsNeeded.toFixed(0)}`);
  return new BigNumber(totalFundsNeeded.toFixed(0));
}

export async function amountMinusGasPrice(
  userData: User,
  account: string,
  gasUnitPrice: string
) {
  if (!userData) {
    return;
  }

  const lockedFundsEth = userData.lockedFunds;
  const lockedFundsWei = new BigNumber(
    ethWeb3.utils.toWei(String(lockedFundsEth))
  );
  const accountBalance = new BigNumber(await ethWeb3.eth.getBalance(account));
  const gasUnitPriceBN = new BigNumber(gasUnitPrice);
  let freeBalance = accountBalance.minus(lockedFundsWei);

  if (freeBalance.isLessThanOrEqualTo(0)) {
    freeBalance = new BigNumber(0);
  }

  const gasPaymentForThisBlock = new BigNumber(
    gasUnitPriceBN.multipliedBy(SINGLE_TRANSACTION_GAS_LIMIT)
  );

  return freeBalance.minus(gasPaymentForThisBlock);
}

export function isDaoExists(propData) {
  return !(
    propData?.daoAddress === null ||
    propData?.daoAddress === "" ||
    propData?.daoAddress === "-"
  );
}

export async function getDaoIfExists(propData): Promise<any> {
  if (!isDaoExists(propData)) {
    return undefined;
  }

  return await createContractInstance(propData?.daoAddress);
}
