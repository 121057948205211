import { useCallback, useState } from "react";
import { BigNumber } from "bignumber.js";
import { Box } from "@mui/material";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Input from "components/Input";
import { toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import Loading from "components/Loading";
import useUserData from "hooks/useUserData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import saveTransaction from "apis/multeez/saveTransaction";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";

export default function WithdrawNft() {
  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();

  const [disableButton, setDisableButton] = useState(false);
  const [buttonName, setButtonName] = useState("Create Withdraw Transaction");
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [withdrawAddress, setWithdrawAddress] = useState("");

  useCheckIfTransactionSent({
    user: userData,
    originAction: TransactionOriginAction.WithdrawNft,
  });

  const handleConfirm = async () => confirm();

  const confirm = async () => {
    if (!userData) {
      return;
    }

    try {
      setDisableButton(true);
      setButtonName("Processing Withdraw Request...");

      const accounts = await ethWeb3.eth.getAccounts();
      let userAddress;

      if (withdrawAddress === "magic") {
        userAddress = accounts[0];
      } else {
        userAddress = withdrawAddress;
      }

      const daoAddress = urlParams.get("daoAddress") || "";

      const userMagicAccount = accounts[0];

      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: METHOD_GAS_LIMIT,
        user: userData,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logError("DAO failed to be created please try again later");
        alert("DAO failed to be created please try again later");
        return "";
      }

      const params = {
        userAddress,
        userId: userData?.id || "",
        userMagicAccount,
        dao,
        daoAddress,
        gasUnitPrice,
        onComplete: () => setDisableButton(true),
      };
      await runWithRetries(nftWithdrawSend, params);
    } catch (e) {
      setButtonName("Failed to withdraw nft");
      await logError("failed to withdraw nft with general error: ");
      await logError(e);
    }
  };

  const handleInputOnChange = useCallback((event) => {
    if (event.target.value) {
      setWithdrawAddress(event.target.value);
    } else {
      setWithdrawAddress("magic");
    }
  }, []);

  const shouldDisable = () => !userData;

  return (
    <Page>
      {!userData && <Loading text="Getting things ready..." />}
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {showAddGasModal && (
          <AddGasModal
            open={showAddGasModal}
            onClose={() => setShowAddGasModal(false)}
            gasPriceInWei={gasNeeded}
            onFundsAdded={confirm}
            userEmail={userData?.email}
          />
        )}
        <Box mt={13} />
        <Input
          type="text"
          variant="standard"
          label="Address to transfer NFT"
          placeholder="Address to transfer NFT"
          onChange={handleInputOnChange}
        />
        <p>
          Estimated Gas Price:{" "}
          <strong>
            {toFixed(
              new BigNumber(
                ethWeb3.utils.fromWei(gasUnitPrice.toString())
              ).multipliedBy(String(METHOD_GAS_LIMIT))
            )}{" "}
            ETH
          </strong>
        </p>
        <Box mt={13} />
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          {buttonName}
        </Button>
      </Box>
    </Page>
  );
}

type NftWithdrawSendParams = {
  userAddress: string;
  userId: string;
  userMagicAccount: string;
  dao: any;
  daoAddress: string;
  gasUnitPrice: string;
  onComplete: () => void;
};

async function nftWithdrawSend({
  userAddress,
  userId,
  userMagicAccount,
  dao,
  daoAddress,
  gasUnitPrice,
  onComplete,
}: NftWithdrawSendParams) {
  const nftWithdrawStatus = await dao.methods
    .transferMyNFT(userAddress)
    .send({
      from: userMagicAccount,
      gasPrice: gasUnitPrice,
      gas: METHOD_GAS_LIMIT,
    })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        userId,
        daoAddress,
        originAction: TransactionOriginAction.WithdrawNft,
        onComplete,
      })
    );
  await logInfo("NFT Withdraw Status: ");
  await logInfo(nftWithdrawStatus);
  return nftWithdrawStatus;
}
