import { useRef, useEffect } from "react";
import BigNumber from "bignumber.js";
import axios from "axios";

export const addEllipses = (text = "", maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength / 2)}...${text.substring(
      text.length - maxLength / 2,
      text.length
    )}`;
  }

  return text;
};

export const toFixed = (amount: string | number | BigNumber) => {
  if (new BigNumber(amount).isNaN()) return 0;
  return new BigNumber(amount).toFixed(4);
};

export const useInterval = (callback: () => any, delay: number) => {
  const savedCallback = useRef<() => any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const getCountryCode = async () => {
  const URL = "https://ipapi.co/json";
  const defaultCode = "US";

  try {
    const { data } = await axios.get(URL);

    if (data?.country_code) {
      return data.country_code;
    }
  } catch (error) {
    console.log("Could not get country code", error);
    return defaultCode;
  }
};
