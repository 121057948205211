import { TextField, styled } from "@mui/material";

export const Input = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "touched",
})<{ touched: boolean }>`
  & .MuiInput-root {
    height: 45px;
  }
  & .MuiInput-input {
    font-weight: 300;
    color: #adcccc;
    color: ${({ touched }) => (touched ? "#000000" : "#adcccc")};
    margin: 0;
    padding: 0;
  }
  & .MuiInputAdornment-root {
    align-items: flex-start;
  }
  & .MuiInputAdornment-root > p {
    color: ${({ touched }) => (touched ? "#000000" : "#adcccc")};
    font-size: 12px;
  }
  & .MuiFormHelperText-root {
    text-align: center;
  }
`;

export const AddressInput = styled(Input)`
  & .MuiInput-input {
    font-size: 16px;
  }
  & .MuiInput-input::placeholder {
    color: #adcccc;
    opacity: 1;
    font-weight: 400;
  }
`;

export const AmountInput = styled(Input)`
  & .MuiInput-input {
    font-size: 36px;
    text-align: center;
  }
`;
