import { styled, Box } from "@mui/material";

export const StyledImage = styled("img")({
  height: 36,
  width: 36,
  borderRadius: 3,
});

export const Pill = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 70px;
  font-size: 10px;
  border-radius: 12px;
`;
