import { Stack, Typography } from "@mui/material";
import { BigNumber } from "bignumber.js";
import * as S from "./ActivityItem.styles";
import { ProposalVoter, ProposalProgress } from "types/common";

const STATUS_DATA = {
  SeatTaken: {
    text: "Seat taken",
    color: "#D8D5F0",
  },
  Locked: {
    text: "Funds locked",
    color: "#D8D5F0",
  },
  Confirmed: {
    text: "Confirmed",
    color: "#D8D5F0",
  },
  BidPlaced: {
    text: "Bid live",
    color: "#D8D5F0",
  },
  OutBid: {
    text: "Outbid",
    color: "#FFC7C7",
  },
  Failed: {
    text: "Failed",
    color: "#FFC7C7",
  },
  Closed: {
    text: "Closed",
    color: "#FFC7C7",
  },
  Won: {
    text: "Owned",
    color: "#C9FFD8",
  },
};

const getProposalStatus = (userId, proposal) => {
  if (proposal?.proposalStatus === ProposalProgress.Failed)
    return STATUS_DATA[proposal.proposalStatus];
  if (proposal?.proposalStatus === ProposalProgress.Won)
    return STATUS_DATA[proposal.proposalStatus];
  if (proposal?.closed) return STATUS_DATA.Closed;
  if (proposal?.proposalStatus === ProposalProgress.BidPlaced)
    return STATUS_DATA[proposal.proposalStatus];
  if (proposal?.proposalStatus === ProposalProgress.OutBid)
    return STATUS_DATA[proposal.proposalStatus];
  const voter = proposal?.voters?.find(
    (voter: ProposalVoter) => voter.dynamoUserId === userId
  );
  if (voter?.fundsTransferred) return STATUS_DATA.Confirmed;
  if (
    voter?.fundsLocked ||
    proposal?.proposalStatus === ProposalProgress.PendingDeployment ||
    proposal?.proposalStatus === ProposalProgress.PendingTransferToDao
  )
    return STATUS_DATA.Locked;

  return STATUS_DATA.SeatTaken;
};

const ActivityItem = ({ userId, proposal }) => {
  const ownershipPrct = new BigNumber(1)
    .dividedBy(proposal?.slots || 1)
    .multipliedBy(100)
    .toFixed(0);

  const status = getProposalStatus(userId, proposal);

  return (
    <Stack
      direction="row"
      gap={2}
      borderBottom="1px solid #adcccc"
      py={1}
      mb={1}
    >
      <S.StyledImage src={proposal?.imageUrl} />
      <Stack mr={3} width={100} minWidth={100}>
        <Typography variant="body3" fontWeight={900}>
          {proposal?.nftName}
        </Typography>
        <Typography variant="caption">{ownershipPrct}% Ownership</Typography>
      </Stack>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body3" fontWeight={900}>
          {proposal?.proposedValue?.toFixed(3)} ETH
        </Typography>
        <S.Pill bgcolor={status.color}>{status.text}</S.Pill>
      </Stack>
    </Stack>
  );
};

export default ActivityItem;
