import axios, { Axios } from "axios";
import { RARIBLE_ETH_CONTRACT_CURRENCY_ID } from "../../utils";
import createBidOrder from "./actions/createBidOrder";
import createDirectSellOrder from "./actions/createDirectSellOrder";
import { RaribleOrder } from "./types";
import createOrdersForBuyAddFunds from "./actions/createOrdersForBuyAddFunds";

const raribleHttpClient = axios.create({
  baseURL: process.env.REACT_APP_RARIBLE_API_V01,
});

class RarirbleClient {
  client: Axios;

  constructor() {
    this.client = raribleHttpClient;
  }

  async encodeOrder(order: RaribleOrder) {
    const encodeRes = await this.client.post(`/order/encoder/order`, order);
    return encodeRes.data;
  }

  async createOrdersForBuyAddFunds(
    tokenAddress: string,
    tokenId: string,
    daoAddress: string,
    directBuySig: string
  ) {
    return createOrdersForBuyAddFunds({
      tokenAddress,
      tokenId,
      daoAddress,
      directBuySig,
    });
  }

  async getNftData({
    tokenAddress,
    tokenId,
  }: {
    tokenAddress: string;
    tokenId: string;
  }) {
    try {
      const res = await this.client.get(
        `/order/orders/sell/byItemAndByStatus`,
        {
          params: {
            currencyId: RARIBLE_ETH_CONTRACT_CURRENCY_ID,
            platform: "RARIBLE",
            status: "ACTIVE",
            contract: tokenAddress,
            tokenId,
          },
        }
      );
      return res.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async createBidOrder(
    tokenAddress: string,
    tokenId: string,
    bidPrice: string,
    daoAddress: string
  ) {
    return createBidOrder(
      tokenAddress,
      tokenId,
      bidPrice,
      daoAddress,
      this.client
    );
  }

  async createDirectSellOrder(
    tokenAddress: string,
    tokenId: string,
    bidPrice: string
  ) {
    return createDirectSellOrder(tokenAddress, tokenId, bidPrice, this.client);
  }
}

const rarible = new RarirbleClient();

export default rarible;
