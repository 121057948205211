import { FC, ReactNode, useCallback } from "react";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../Button";
import { ReactComponent as Logo } from "assets/logo.svg";
import useAuth from "context/useAuth";

type Props = {
  children: ReactNode;
  showLogoutBtn?: boolean;
  showLogo?: boolean;
};

const Page: FC<Props> = ({ children, showLogoutBtn, showLogo }) => {
  const { signout } = useAuth();
  const theme = useTheme();

  const logout = useCallback(() => {
    Swal.fire({
      title: "You have successfully logged out. 🙂",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    signout();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 500,
        height: "100%",
        width: "100%",
        padding: theme.spacing(0, 4),
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {showLogoutBtn && (
          <Box
            sx={{
              marginBottom: "30px",
            }}
          >
            <Box height={10} />
            <Button
              isSmall
              onClick={logout}
              backgroundColor="#ffffff"
              color="#000000"
              boxShadow="9px 9px 26px rgba(95, 81, 236, 0.1)"
            >
              <Typography variant="subtitle2">Logout</Typography>
            </Button>
          </Box>
        )}
      </Box>
      {showLogo && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <Logo width="13px" />
          </Box>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default Page;
