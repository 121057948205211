import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { Box, Stack, Typography } from "@mui/material";
import { CreateBuyoutRequestParams } from "./types";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import useUserData from "hooks/useUserData";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import useProposalData from "hooks/useProposalData";
import saveTransaction from "apis/multeez/saveTransaction";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";

export default function RequestBuyOut() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );
  const gasUnitPrice = useCurrentGasPrice();

  const [disableButton, setDisableButton] = useState(false);
  const [buttonName, setButtonName] = useState("Create Buyout Request");
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [finalSellerAmount, setFinalSellerAmount] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.RequestBuyout,
  });

  useEffect(() => {
    if (!proposalData) {
      return;
    }

    setDisableButton(true);

    const proposalVal = new BigNumber(proposalData?.proposedValue);
    const finalSellerAmountEth = proposalVal.multipliedBy("0.98");
    setFinalSellerAmount(finalSellerAmountEth.toFixed(5));
    setDisableButton(false);
  }, [proposalData]);

  const shouldDisable = () => !proposalData || !userData;

  const handleConfirm = async () => confirm();

  const confirm = async () => {
    setIsActionInProgress(true);

    if (!proposalData) {
      return;
    }

    if (!userData) {
      return;
    }

    try {
      setDisableButton(true);
      setButtonName("Creating Buyout Request...");
      await logInfo("Proposal data:");
      await logInfo(proposalData);
      const daoAddress = proposalData?.daoAddress;
      const proposedShare = Number(proposalData?.proposedShare) || 0;
      const totalUserShare = Number(proposalData?.totalUserShare) || 0;
      const buyoutPriceEther = proposalData?.proposedValue;

      const DaoDecimals = 100000;
      const buyoutPercent = Math.floor(
        (proposedShare / totalUserShare) * DaoDecimals
      );
      const buyoutPrice = ethWeb3.utils.toWei(String(buyoutPriceEther));
      const buyoutPriceBN = new BigNumber(buyoutPrice);

      const BackendValue = urlParams.get("value");
      const BackendShare = urlParams.get("share");

      if (
        Number(BackendValue) !== Number(buyoutPriceEther) ||
        Number(BackendShare) !== Number(proposedShare)
      ) {
        await logInfo(
          "Proposal is out of date user trying to activate old buyout request"
        );
        // alert('Proposal is out of date')
        setButtonName("Proposal is out of date");
        return "";
      }

      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];

      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        user: userData,
        gasUnits: METHOD_GAS_LIMIT,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        setShowAddGasModal(true);
        return;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logInfo(
          "DAO instance failed to be created please try again later, no money was spent"
        );
        alert(
          "DAO failed to be created please try again later, no money was spent"
        );
        return "";
      }

      const buyerMail = urlParams.get("buyerMail");
      const buyerUserId = `USER_${buyerMail}`;
      const buyoutDecimal = 0;
      await createBuyoutRequest({
        userAddress,
        buyoutPrice: buyoutPriceBN.toString(),
        buyoutPercent,
        buyoutDecimal,
        dao,
        gasUnitPrice,
        proposal: proposalData,
        userId: userData?.id || "",
        buyerUserId,
        onComplete: () => setIsActionInProgress(false),
      });
    } catch (e) {
      setButtonName("Failed to create Buyout request");
      await logError("general error with error:");
      await logError(String(e));
    }
  };

  return (
    <Page>
      {(!userData || isActionInProgress) && (
        <Loading
          text={`${
            !userData ? "Loading" : "Getting things ready..."
          }, please wait...`}
        />
      )}
      <Stack>
        {showAddGasModal && (
          <AddGasModal
            open={showAddGasModal}
            onClose={() => setShowAddGasModal(false)}
            gasPriceInWei={gasNeeded.toString()}
            onFundsAdded={confirm}
            userEmail={userData?.email || ""}
          />
        )}
        <Typography variant="h5" align="center">
          We&apos;re taking care of stuff, hold on
        </Typography>
        <Box my={3}>
          <Typography variant="body" mb={2}>
            Estimated Gas Price:{" "}
            <strong>
              {toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(METHOD_GAS_LIMIT))
              )}{" "}
              ETH
            </strong>
          </Typography>
          <Typography variant="body" mb={2}>
            Once approved, a 2% service fee will be deducted from the
            transaction
          </Typography>
          <Typography variant="body">
            You will receive : <strong>{finalSellerAmount} ETH</strong>
          </Typography>
        </Box>
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          {buttonName}
        </Button>
      </Stack>
    </Page>
  );
}

async function createBuyoutRequest({
  userAddress,
  buyoutPrice,
  buyoutPercent,
  buyoutDecimal,
  dao,
  gasUnitPrice,
  proposal,
  userId,
  buyerUserId,
  onComplete,
}: CreateBuyoutRequestParams) {
  return dao.methods
    .requestBuyOut(buyoutPrice, buyoutPercent, buyoutDecimal)
    .send({ from: userAddress, gas: METHOD_GAS_LIMIT, gasPrice: gasUnitPrice })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        proposal,
        userId,
        buyoutSecondUserId: buyerUserId,
        onComplete,
        transactionHash,
        originAction: TransactionOriginAction.RequestBuyout,
      })
    );
}
