import Web3 from "web3";
import { ERC1155 } from "multi-token-standard-abi";
import { ERC721ABI } from "../contract/ERC721";

const ERC721NameConvention = "ERC721";

export const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

export const transferNft = async (
  contractAddress: string,
  tokenId: string,
  to: string,
  nftType: string
) => {
  const { ethereum, web3: metamask } = window;
  // connect to metamask (will ask user to connect if he is not already) and take the first account
  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  // transfer funds from the metamask wallet to the local magic defined wallet
  // Get the contract instance using your contract's abi and address:
  const metamaskWeb3 = new Web3(metamask.currentProvider);

  if (nftType === ERC721NameConvention) {
    const contractInstance = new metamaskWeb3.eth.Contract(
      ERC721ABI.abi,
      contractAddress
    );
    await contractInstance.methods
      .safeTransferFrom(accounts[0], to, tokenId)
      .send({ from: accounts[0] });
  } else {
    const contractInstance = new metamaskWeb3.eth.Contract(
      ERC1155.abi,
      contractAddress
    );
    await contractInstance.methods
      .safeTransferFrom(accounts[0], to, tokenId, 1, 0)
      .send({ from: accounts[0] });
  }
};
