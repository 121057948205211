import { useCallback, useState, useMemo } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { BigNumber } from "bignumber.js";
import * as S from "./Deploy.styles";
import useDeployDaoOrAddFunds from "./hooks/useDeployDaoOrAddFunds";
import useBuyNft from "./hooks/useBuyNft";
import { useUserProposalInfo } from "hooks/useUserProposalInfo";
import { TransactionStatus } from "types/common";
import useProposalData from "hooks/useProposalData";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import AddGasModal from "components/AddGasModal";
import useUserData from "hooks/useUserData";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import useQueryParams from "hooks/useQueryParams";

export default function Deploy() {
  const urlParams = useQueryParams();
  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const [disableButton, setDisableButton] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);

  const proposalInfo = useUserProposalInfo({
    proposal: proposalData,
    setDisplayedBalance: () => {
      return "";
    },
    user: userData,
  });

  const { userBid, userFees } = proposalInfo;

  const { missingFunds, isDeployInProgress, deployDaoOrAddFunds } =
    useDeployDaoOrAddFunds({
      gasUnitPrice,
      proposal: proposalData,
      setShowAddGasModal,
    });

  const { buyNft, buyNftLoading } = useBuyNft({
    proposal: proposalData,
    user: userData,
  });

  const addedFundsToDao = useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.AddFundsToDao,
    hideAlert: userData && proposalData?.isDirectBuy,
  });

  const purchasedNft = useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.DirectBuy,
  });

  const shouldDisableButton = () =>
    !proposalData ||
    !userData ||
    disableButton ||
    purchasedNft.status === TransactionStatus.Success;

  const onConfirm = useCallback(async () => {
    setDisableButton(true);

    if (
      addedFundsToDao.status === TransactionStatus.Success &&
      proposalData?.daoAddress !== "-" &&
      proposalData?.isDirectBuy
    ) {
      await buyNft();
    } else {
      await deployDaoOrAddFunds();
    }

    setDisableButton(false);
  }, [addedFundsToDao, buyNft, deployDaoOrAddFunds, proposalData]);

  const ownershipPrct = new BigNumber(1)
    .dividedBy(proposalData?.slots || 1)
    .multipliedBy(100)
    .toFixed(2);

  const buttonText = useMemo(() => {
    if (proposalData?.daoAddress === "-") {
      return "Deploy DAO";
    }

    if (addedFundsToDao.status !== TransactionStatus.Success) {
      return "Confirm";
    }

    if (proposalData?.isDirectBuy) {
      return "Purchase";
    }

    return "Done";
  }, [proposalData, userData, addedFundsToDao]);

  return (
    <Page>
      {(!userData || !proposalData || isDeployInProgress || buyNftLoading) && (
        <Loading text="Loading, please wait..." />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={missingFunds}
          onFundsAdded={deployDaoOrAddFunds}
          userEmail={userData?.email || ""}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Confirmation
        </Typography>
        <S.NFTCard>
          <S.NFTImage
            src={proposalData?.imageUrl}
            alt={proposalData?.nftName}
          />
          <Stack>
            <Typography variant="body" fontWeight={900} lineHeight={1.4}>
              {proposalData?.nftName}
            </Typography>
            <Typography variant="body3" fontWeight={900} color="#4F42CC">
              {proposalData?.slots || 1} Owner
              {proposalData && proposalData?.slots > 1 ? "s" : ""}
            </Typography>
            <Typography variant="body3">
              Ownership: <strong>{ownershipPrct}%</strong>
            </Typography>
            <Typography variant="body3">
              Total NFT price:
              <strong> {proposalData?.proposedValue} ETH</strong>
            </Typography>
          </Stack>
        </S.NFTCard>
        <Typography variant="subtitle2" fontWeight={900} mt={4} mb={2}>
          Transaction Recap
        </Typography>
        <Stack mb={5}>
          {[
            ["Your contribution", `${userBid} ETH`],
            ["Estimated fee*", `${userFees} ETH`],
            ["Multeez service fee", "Free"],
          ].map(([title, value]) => (
            <Box key={title} display="flex" justifyContent="space-between">
              <Typography component="span" variant="subtitle2">
                {title}
              </Typography>
              <Typography component="span" variant="subtitle2">
                {value}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Button disabled={shouldDisableButton()} onClick={onConfirm}>
          <Typography variant="h6" fontWeight={900} component="span">
            {buttonText}
          </Typography>
        </Button>
        <Typography variant="subtitle2" align="center" mt={7}>
          *This includes the DAO fee and transaction fees. In case of extreme
          gas fluctuations, you will be requested to add sufficient funds.
        </Typography>
      </Stack>
    </Page>
  );
}
