import { useState } from "react";
import { BigNumber } from "bignumber.js";
import { Stack, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { baseUrl } from "../Deploy/utils";
import { isMetaMaskInstalled, transferNft } from "../metamaskLiquidAsset";
import {
  getDaoIfExists,
  calcMissingFundsForActionWei,
  DEPLOY_DAO_GAS_UNITS,
  isDaoExists,
  METHOD_GAS_LIMIT,
} from "../../utils";
import { getNftType } from "./utils/getNftType";
import { ethWeb3 } from "magic";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { toFixed } from "utils/common";
import { logInfo, logError } from "utils/logger";
import useUserData from "hooks/useUserData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import useProposalData from "hooks/useProposalData";
import { postAuthRequest } from "utils/auth";

export default function Byon() {
  const { data: userData } = useUserData();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const gasUnitPrice = useCurrentGasPrice();

  const [loading, setLoading] = useState(false);
  const [buttonName, setButtonName] = useState("Confirm");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");

  const confirm = async () => {
    if (!proposalData || !userData) {
      return;
    }

    await logInfo("starting BYON flow");
    setLoading(true);
    setIsButtonDisabled(true);
    setButtonName("Deploying");

    const {
      telegramChatId,
      tokenAddress: nftAddress,
      tokenID: nftTokenId,
    } = proposalData;

    await logInfo(`group id for Byon request ${telegramChatId}`);
    await logInfo(`nft for Byon request ${nftAddress} : ${nftTokenId}`);

    // to bring accounts
    const accounts = await ethWeb3.eth.getAccounts();
    const isMetaInstall = isMetaMaskInstalled();
    let nftType;

    if (isMetaInstall) {
      const { ethereum } = window;
      const accountsMeta = await ethereum.request({
        method: "eth_requestAccounts",
      });
      nftType = await getNftType(nftAddress, nftTokenId, accountsMeta[0]);
    } else {
      nftType = await getNftType(nftAddress, nftTokenId, accounts[0]);
    }

    if (nftType === -1) {
      setButtonName("Not your NFT");
      Swal.fire(
        "Oops!",
        "Either this NFT is not owned by you, or it was minted lazily. You can try again with a different NFT.",
        "error"
      );
      const notOwnedNftUrl = `${baseUrl}/api/v0/importfail/${nftAddress}/${nftTokenId}/${telegramChatId}`;
      await postAuthRequest(notOwnedNftUrl);
      setLoading(false);
      return "";
    }

    await logInfo(`nft type for Byon request ${String(nftType)}`);

    const howMuchGasNeeded = await calcMissingFundsForActionWei({
      gasUnitPrice,
      gasUnits: DEPLOY_DAO_GAS_UNITS,
      user: userData,
      additionalFeesInWei: new BigNumber(
        proposalData?.voterAmountOwedToMulteez
      ),
    });

    if (howMuchGasNeeded.isGreaterThan("0")) {
      setGasNeeded(howMuchGasNeeded.toString());
      await logInfo(
        `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
      );
      setShowAddGasModal(true);
      setLoading(false);
      return;
    }

    const daoAddress = proposalData.daoAddress;

    if (!daoAddress || !isDaoExists(proposalData)) {
      await logError(`proposal ${proposalData.id} do not hold dao address`);
      return;
    }

    await logInfo(`dao proposal address ${daoAddress}`);
    const dao = await getDaoIfExists(proposalData);
    await logInfo(`dao after set ${dao.options.address}`);
    await dao.methods.deployPayBackForByon().send({
      from: userData.magicWallet,
      value: proposalData.voterAmountOwedToMulteez,
      gasPrice: gasUnitPrice,
      gas: METHOD_GAS_LIMIT,
    });
    await transferNft(nftAddress, nftTokenId, daoAddress, nftType);

    const url = `${baseUrl}/api/v0/addexternalnft/${nftAddress}/${nftTokenId}/${userData?.email}/${telegramChatId}/${daoAddress}`;
    const result = await postAuthRequest(url);

    if (result.status === 200) {
      await logInfo(`db updated`);
      Swal.fire("Success!", "NFT has been imported successfully.", "success");
      setButtonName("Success!");
    } else {
      await logError("DB Not Updated");
    }

    setLoading(false);
  };

  return (
    <Page>
      {!userData && <Loading text="Getting things ready..." />}
      <Stack>
        {showAddGasModal && (
          <AddGasModal
            open={showAddGasModal}
            onClose={() => setShowAddGasModal(false)}
            gasPriceInWei={gasNeeded}
            onFundsAdded={confirm}
            userEmail={userData?.email || ""}
          />
        )}
        <Typography variant="h5" align="center">
          We&apos;re taking care of stuff, hold on
        </Typography>
        <Typography variant="body">
          Estimated Gas Price:{" "}
          <strong>
            {toFixed(
              new BigNumber(
                ethWeb3.utils.fromWei(gasUnitPrice.toString())
              ).multipliedBy(String(DEPLOY_DAO_GAS_UNITS))
            )}{" "}
            ETH
          </strong>
        </Typography>
        <Button loading={loading} disabled={isButtonDisabled} onClick={confirm}>
          {buttonName}
        </Button>
      </Stack>
    </Page>
  );
}
