import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const BalanceBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  flex: 1,
  height: 90,
  borderRadius: 12,
  padding: theme.spacing(0.5, 2),
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 3.12%, #FFFFFF 99.99%, #FFFFFF 100%)",
  filter: "drop-shadow(8px 8px 20px rgba(95, 81, 236, 0.06))",
}));

export const AddressBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: 40,
  backgroundColor: "#ffffff",
  margin: theme.spacing(1.25, 0, 3),
  borderRadius: 12,
  padding: theme.spacing(0.5, 2),
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 3.12%, #FFFFFF 99.99%, #FFFFFF 100%)",
  filter: "drop-shadow(8px 8px 20px rgba(95, 81, 236, 0.06))",
}));

export const BottomSection = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  margin: theme.spacing(1, -2.5, 0),
  padding: theme.spacing(0, 4, 4),
  borderTopLeftRadius: 50,
  borderTopRightRadius: 50,
  position: "relative",
  minHeight: 490,
  height: 490,
}));
