import { postAuthRequest } from "./auth";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;
const url = `${baseUrl}/api/v0/log`;

async function sendLog(message, level) {
  const payload = {
    message,
    level: level,
    pathname: window.location.pathname,
    search: window.location.search,
    href: window.location.href,
  };

  if (process.env.REACT_APP_ETHEREUM_NETWORK_NAME === "rinkeby") {
    console.log(message);
  }

  await postAuthRequest(url, payload);
}

export async function logInfo(message) {
  sendLog(message, "info");
}

export function logError(message) {
  sendLog(message, "error");
}
