import { FC } from "react";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import { styled } from "@mui/material/styles";

type Props = {
  to: string;
  icon: FC;
  label: string;
} & LinkProps;

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? "#000000" : "#566665")};
  font-weight: ${({ isActive }) => (isActive ? 800 : 400)};
  border-bottom: ${({ isActive }) => (isActive ? "1px solid #000000" : "none")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: 1rem;
  & > svg {
    fill: ${({ isActive }) => (isActive ? "#000000" : "#566665")};
  }
`;

const TabLink: FC<Props> = ({ icon: Icon, label, to, ...rest }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <StyledLink isActive={!!match} to={to} {...rest}>
      <Icon />
      {label}
    </StyledLink>
  );
};

export default TabLink;
