import { useState } from "react";
import BigNumber from "bignumber.js";
import { Stack, Typography } from "@mui/material";
import { getAuthRequest } from "utils/auth";
import { toFixed } from "utils/common";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  isDaoHoldsThisNft,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { logError, logInfo } from "utils/logger";
import useUserData from "hooks/useUserData";
import useProposalData from "hooks/useProposalData";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import saveTransaction from "apis/multeez/saveTransaction";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

export default function BuyOut() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const [disableButton, setDisableButton] = useState(false);
  const [buttonName, setButtonName] = useState("Create Buyout Transaction");
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.Buyout,
  });

  const shouldDisable = () => !proposalData || !userData;

  const handleConfirm = async () => {
    setIsActionInProgress(true);
    await confirm();
  };

  const confirm = async () => {
    try {
      if (!proposalData) {
        return;
      }

      if (!userData) {
        return;
      }

      setDisableButton(true);
      setButtonName("Creating Buyout Request...");
      const daoAddress = proposalData?.daoAddress;
      const proposedShare = Number(proposalData?.proposedShare) || 0;
      const totalUserShare = Number(proposalData?.totalUserShare) || 1;
      const buyoutPriceEther = proposalData?.proposedValue;
      const nftAddress = proposalData?.tokenAddress || "";
      const nftId = proposalData?.tokenID || "";

      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];

      const buyoutPrice = ethWeb3.utils.toWei(String(buyoutPriceEther));
      const buyoutPriceBN = new BigNumber(buyoutPrice);
      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: METHOD_GAS_LIMIT,
        user: userData,
        additionalFeesInWei: new BigNumber(buyoutPrice),
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const DaoDecimals = 100000;
      const buyoutPercent = Math.floor(
        (proposedShare / totalUserShare) * DaoDecimals
      );
      const sellerMail = urlParams.get("sellerMail");

      const endpoint = `${baseUrl}/api/v0/userbymail/${sellerMail}`;
      let magicAddressSeller = "";
      const res = await getAuthRequest(endpoint);

      if (res.status === 200) {
        magicAddressSeller = res.data.magicWallet;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logInfo(`DAO instance failed to be created please ${daoAddress}`);
        alert(
          "DAO failed to be created please try again later no Eth was used"
        );
        return "";
      }

      const buyoutDecimal = 0;

      try {
        await logInfo("checking is dao holds nft:");
        const _isDaoHoldsNFT = await isDaoHoldsThisNft(
          nftAddress,
          nftId,
          daoAddress || ""
        );

        if (!_isDaoHoldsNFT) {
          setButtonName(
            "Failed the buyer tried to trick you! but no money was transferred "
          );
          await logInfo("Dao is not holding the proposal nft");
          return "";
        }
      } catch (eHolding) {
        await logError(
          "is dao holds nft check crashed (most likely due to lazy nft and therefor dao is not holding it) with error"
        );
        await logError(eHolding);
        setButtonName("Failed no money was transferred ");
        return "";
      }

      const sellerUserId = `USER_${sellerMail}`;
      await executeBuyout([
        userAddress,
        magicAddressSeller,
        buyoutPriceBN.toString(),
        buyoutPercent,
        buyoutDecimal,
        dao,
        gasUnitPrice,
        proposalData,
        userData?.id,
        sellerUserId,
        setIsActionInProgress,
      ]);
    } catch (e) {
      setButtonName("Failed to create Buyout request");
      logError();
      logError(e);
    }
  };

  return (
    <Page>
      {(!userData || isActionInProgress) && (
        <Loading text={"Loading, please wait"} />
      )}
      <Stack alignItems="center">
        {showAddGasModal && (
          <AddGasModal
            open={showAddGasModal}
            onClose={() => setShowAddGasModal(false)}
            gasPriceInWei={gasNeeded.toString()}
            onFundsAdded={confirm}
            userEmail={userData?.email || ""}
          />
        )}
        <Typography variant="body">
          We&apos;re taking care of stuff, hold on
        </Typography>
        <Typography variant="body">
          Estimated Gas Price:{" "}
          <strong>
            {toFixed(
              new BigNumber(
                ethWeb3.utils.fromWei(gasUnitPrice.toString())
              ).multipliedBy(String(METHOD_GAS_LIMIT))
            )}{" "}
            ETH
          </strong>
        </Typography>
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          {buttonName}
        </Button>
      </Stack>
    </Page>
  );
}

async function executeBuyout([
  userAddress,
  sellerAddress,
  buyoutPrice,
  buyoutPercent,
  buyoutDecimal,
  dao,
  gasUnitPrice,
  proposal,
  buyerUserId,
  sellerUserId,
  setIsActionInProgress,
]) {
  return dao.methods
    .buyout(sellerAddress, buyoutPercent, buyoutDecimal)
    .send({
      from: userAddress,
      value: buyoutPrice,
      gas: METHOD_GAS_LIMIT,
      gasPrice: gasUnitPrice,
    })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        proposal,
        userId: buyerUserId,
        originAction: TransactionOriginAction.Buyout,
        onComplete: () => setIsActionInProgress(false),
        buyoutSecondUserId: sellerUserId,
      })
    );
}
