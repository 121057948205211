import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";
import BigNumber from "bignumber.js";
import { ethWeb3 } from "../magic";
import { postAuthRequest } from "./auth";

const { ethereum } = window;

type MetamaskTransferParams = {
  amountToSendInEth: string;
  userEmail: string;
  publicAddress: string;
  setEthAmountToAdd: (amount: number) => void;
  setIsTransactionInProgress: (isInProg: boolean) => void;
};

//check if the MetaMask extension is installed
export const isMetaMaskInstalled = async () => {
  return Boolean(ethereum && ethereum.isMetaMask);
};

export const metamaskTransfer = async ({
  amountToSendInEth,
  userEmail,
  publicAddress,
  setEthAmountToAdd,
  setIsTransactionInProgress,
}: MetamaskTransferParams) => {
  const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

  const amountWei = ethWeb3.utils.toWei(amountToSendInEth);

  if (isMobile) {
    // metamask link needs value in the form of "3e17"
    const amountWithExponent = new BigNumber(amountWei)
      .toExponential()
      .replace("+", "");
    window.location.href = `https://metamask.app.link/send/${publicAddress}?value=${amountWithExponent}`;

    return;
  }

  const amountToSendInHex = new BigNumber(amountWei).toString(16);

  postAuthRequest(`${baseUrl}/api/v0/metamaskuse/${userEmail}`);
  // connect to metamask (will ask user to connect if he is not already) and take the first account
  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  // transfer funds from the metamask wallet to the local magic defined wallet
  ethereum
    .request({
      method: "eth_sendTransaction",
      params: [
        {
          from: accounts[0],
          to: publicAddress,
          value: amountToSendInHex,
        },
      ],
    })
    .then(() => {
      setIsTransactionInProgress(true);
      setEthAmountToAdd(0);
    })
    .catch((e) => {
      setIsTransactionInProgress(false);
      Swal.fire({
        title: "Oops!",
        text: `Metamask Error: ${e?.message}`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    });
};

export const openMetaMask = async (publicAddress) => {
  if (isMobile) {
    window.location.href = "https://metamask.app.link/dapp/";
    return;
  }

  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  ethereum.request({
    method: "eth_sendTransaction",
    params: [
      {
        from: accounts[0],
        to: publicAddress,
      },
    ],
  });
};
