import { Stack, Typography } from "@mui/material";
import ActivityItem from "./ActivityItem";
import useProposalsData from "hooks/useProposalsData";
import { Proposal } from "types/common";
import { useUser } from "pages/Wallet";

const onlyBuy = (p: Proposal) => p.proposeKind === "buy";

const Activity = () => {
  const { userData } = useUser();
  const { data: proposals } = useProposalsData();

  return (
    <>
      <Typography variant="body1" fontWeight={800} mt={5} mb={2} align="center">
        Activity
      </Typography>
      {proposals?.length ? (
        <Stack
          sx={{
            height: 400,
            overflowY: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {proposals
            ?.filter(onlyBuy)
            .map((proposal: Proposal, index: number) => (
              <ActivityItem
                userId={userData?.id}
                proposal={proposal}
                key={index}
              />
            ))}
        </Stack>
      ) : (
        <Typography variant="h5" align="center">
          No data
        </Typography>
      )}
    </>
  );
};

export default Activity;
